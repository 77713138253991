@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;700&display=swap');

/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} */

body {
  font-family: "Titillium Web", sans-serif;
  
  font-style: normal;
}
.title {
  color: white;
  text-align: center;
  font-size: 1.7rem;
  padding: .5rem;
  font-weight: 400;
}

.logo {
  width: 30px;
  padding: .8rem;
}

.container {
  display: flex;
  justify-content: center;

  
}

.hero-title-big {
  font-size: 3rem;
  color: white;
  font-weight: 300;
  margin-top: auto;
}

/* .hero-title-smaill-flex{ 
 display: flex;
 flex-direction: column;
 justify-self: end;
} */

.hero-title-small {
  font-size: 1rem;
  color: white;
  font-weight: 300;
  /* margin-bottom: auto */
  margin-bottom: auto;
  margin-left: 1rem;
}



.hero-container {
  display: flex;
  margin-top: 2rem;
  margin-left: 2rem;
  margin-bottom: -.5rem;
}

.hero-subtitle {
  color: white;
  font-weight: 600;
  font-size: 1rem;
  margin-left: 2rem;
  
}

.hero-intro {
  color: white;
  font-weight: 200;
  margin-left: 2rem;
  font-size: .9rem;
}

.courses-image {
  max-width: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
}

a {
  color: white;
  text-align: center;
}

.down-icon {
  color: white;
  font-size: 1.2rem;
  text-align: center;
}

.hero-down-center {
  text-align: center;
}

.hero-atag-center {
  text-align: center;
  margin: 2rem 0;
}